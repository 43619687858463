<template>
    <el-dialog :title="title"
               v-model="show"
               :close-on-click-modal="false"
               width="480px"
               @close="closeDialog"
               center>
        <!-- 改成自動續費-->
        <div class="renew-el-dialog"
             v-if="renewType =='automatic'">
            <div v-if="!nextStep&& !callAPI">
                <div>您勾選的續費域名數: 共 <span class="span-color-primary">{{checkList.length}}</span> 筆,即將改為自動續費且在到期續約日產生訂單</div>
            </div>
            <div v-else-if="!nextStep&&callAPI">
                <div>待變更為自動續費的域名數共<span class="span-color-primary">{{checkList.length}}</span>筆</div>
                <el-progress :percentage="percentage"
                             :status="progressStatus"
                             class="progress"></el-progress>
            </div>
            <div v-else>
                <div>共 <span class="span-color-primary">{{successCount}}</span>筆域名訂閱方式改為自動續費 ,且在原續約日到期後產生訂單</div>
                <br>
                <div>詳情請點擊查詢<el-link href="/renewRecord">【自動續費變更紀錄】</el-link>
                </div>
            </div>
        </div>
        <!-- 取消自動續費-->
        <div class="renew-el-dialog"
             v-else-if="renewType =='automaticCanncel'">
            <div v-if="!nextStep&& !callAPI">
                <div>您勾選的域名數: 共 <span class="span-color-primary">{{checkList.length}}</span> 筆,即將取消自動續費</div><br>
                <div>你可以如常使用服務至到期日，或是使用手動續約完成續約</div>
            </div>
            <div v-else-if="!nextStep&&callAPI">
                <div>待取消自動續費的域名數共<span class="span-color-primary">{{checkList.length}}</span>筆</div>
                <el-progress :percentage="percentage"
                             :status="progressStatus"
                             class="progress"></el-progress>
            </div>
            <div v-else>
                <div>共 <span class="span-color-primary">{{successCount}}</span>域名取消自動續費 ,你可以如常使用服務至到期日，或是手動續約</div>
                <br>
                <div>詳情請點擊查詢<el-link href="/renewRecord">【自動續費變更紀錄】</el-link>
                </div>
            </div>
        </div>

        <template #footer>
            <span class="dialog-footer"
                  v-if="!nextStep&& !callAPI">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary"
                           @click="onRenew">確認{{textForm[0]}}</el-button>
            </span>
            <span class="dialog-footer"
                  v-else-if="!nextStep&&callAPI">
                <el-tooltip content="目前域名付款中,確定取消付款?"
                            placement="top"
                            effect="light">
                    <el-button type="primary"
                               @click="stop()">停止{{textForm[0]}}</el-button>
                </el-tooltip>
            </span>
            <span class="dialog-footer"
                  v-else>
                <el-button @click="closeDialog">關閉</el-button>
                <el-button type="primary"
                           @click="toPage('renewRecord')">{{textForm[0]}}結果查詢</el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { renewSetting } from '../../../../api/index'
export default {
    props: {
        dialogTitle: {
            type: String,
            default: '',
        },
        renewType: {
            type: String,
            default: '',
        },
        editVisible: {
            type: Boolean,
            default: false,
        },
        textForm: {
            type: Array,
        },
        checkList: {
            type: Array,
        },
    },
    data() {
        return {
            show: this.editVisible, //燈窗顯示與否
            title: this.dialogTitle, //談窗標題
            nextStep: false, //API完成是否顯示完成結果
            callAPI: false, //開始API
            stopAPI: false, //停止API
            percentage: 0,
            progressStatus: '',
            successCount: 0, //成功幾筆
            errorCount: 0, //失敗幾筆
        }
    },
    watch: {
        editVisible() {
            this.show = this.editVisible
        },
    },
    methods: {
        //關閉談窗
        closeDialog() {
            this.callAPI = false
            this.nextStep = false
            this.stopAPI = false
            this.successCount = 0 //成功歸零
            this.errorCount = 0 //失敗歸零
            this.progressStatus = ''
            this.percentage = 0
            this.$emit('close-dialog')
        },
        //呼叫API
        async onRenew() {
            if (this.renewType == 'automatic') {
                this.title = '變更即將完成，請勿關閉視窗'
            } else {
                this.title = '即將取消自動續費,請勿關閉視窗'
            }

            this.callAPI = true
            for (let i = 0; i < this.checkList.length; i++) {
                if (this.stopAPI) {
                    //停止
                    this.progressStatus = 'exception'
                    this.errorCount = this.errorCount + this.checkList.length - i
                } else {
                    //callAPI
                    if (this.renewType == 'automatic') {
                        await this.callAutomaticSetting(this.checkList[i], 'Y')
                    } else {
                        await this.callAutomaticSetting(this.checkList[i], 'N')
                    }
                    this.GetPercent(i + 1, this.checkList.length)
                }
            }
            if (this.renewType == 'automatic') {
                this.title = '變更完成通知'
            } else {
                this.title = '取消自動續費通知'
            }
            this.$emit('refresh-data') //跑完API刷新數據
            setTimeout(() => {
                this.nextStep = true
            }, 1000)
        },
        async callAutomaticSetting(id, auto) {
            //自動續費
            var ret = await new Promise((resolve) => {
                let data = {
                    domain_id: id,
                    is_renew_auto: auto,
                }
                renewSetting(data).then((res) => {
                    if (res.code == 300) {
                        if (res.data.data_info.is_success == 'Y') {
                            this.successCount++
                        } else {
                            this.errorCount++
                        }
                    } else if (res.code == 500) {
                        this.errorCount++
                    }
                    resolve()
                })
            })
            return ret
        },
        toPage() {
            let page = 'renewRecord'
            this.$emit('to-page', page)
        },
        //停止API
        stop() {
            this.stopAPI = true
        },
        /// 求百分比
        GetPercent(num, total) {
            num = parseFloat(num)
            total = parseFloat(total)
            this.percentage = Math.round((num / total) * 10000) / 100.0
        },
    },
}
</script>

<style lang="scss" scoped>
.progress {
    padding: 10px 0;
}
.span-color-primary {
    color: #409eff;
}
.span-color-danger {
    color: #ff0000;
}
.renew-el-dialog {
    text-align: center;
}
</style>