<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 續費管理
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="title">

                <h2>90天內到期域名列表
                </h2>
                <el-button @click="toPage('renewRecord')"
                           round>續費紀錄</el-button>

            </div>
            <el-tabs v-model="activeName"
                     type="card"
                     @tab-click="changeTabs">
                <el-tab-pane label="非自動續費域名"
                             name="nonAutomatic"></el-tab-pane>
                <el-tab-pane label="自動續費域名"
                             name="automatic"></el-tab-pane>
            </el-tabs>
            <el-form ref="formRenew"
                     :inline="true"
                     :model="formInline"
                     class="domain-form-inline"
                     @submit.prevent>
                <el-form-item label="域名:"
                              prop="domain">
                    <el-input v-model="formInline.domain"
                              placeholder="請輸入域名"></el-input>
                </el-form-item>
                <el-form-item label="網域來源:"
                              prop="remark">
                    <el-select v-model="formInline.remark"
                               multiple
                               collapse-tags
                               placeholder="請選擇網域來源"
                               class="selectWidth">
                        <el-option v-for="(item,index) in remarkList"
                                   :label="item.text"
                                   :value="item.value"
                                   :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="分享來源:">
                    <el-input v-model="formInline.share_from"
                              placeholder="請填入分享來源商戶ID"></el-input>
                </el-form-item>
                <el-form-item label="分享給:">
                    <el-input v-model="formInline.share_to"
                              placeholder="請填入已分享的商戶ID"></el-input>
                </el-form-item>
                <el-form-item label="域名到期日:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="daterange"
                                    :shortcuts="shortcuts"
                                    range-separator="至"
                                    :disabled-date="disabledDate"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">清除</el-button>
                </el-form-item>
            </el-form>
            <el-form :inline="true"
                     class="domain-form-inline">
                <el-form-item v-if="activeName=='nonAutomatic'">
                    <div v-if="multipleSelection.length>0">
                        <el-button @click="openManualDialog('automatic','變更為自動續費的域名')">變更為自動續費<i class="el-icon-lx-add el-icon--right"></i></el-button>
                    </div>
                    <div v-else>
                        <el-button plain
                                   disabled>變更為自動續費<i class="el-icon-lx-add el-icon--right"></i></el-button>
                    </div>
                </el-form-item>
                <el-form-item v-else>
                    <div v-if="multipleSelection.length>0">
                        <el-button @click="openManualDialog('automaticCanncel','取消自動續費的域名')">取消自動續費<i class="el-icon-lx-add el-icon--right"></i></el-button>
                    </div>
                    <div v-else>
                        <el-button plain
                                   disabled>取消自動續費<i class="el-icon-lx-add el-icon--right"></i></el-button>
                    </div>
                </el-form-item>
                <el-form-item>
                    <div>共勾選 <span class="span-color-primary">{{multipleSelection.length}}</span> 筆, 總共 <span class="span-color-primary">{{totalCount}}</span> 筆</div>
                </el-form-item>
            </el-form>
            <el-alert title="請注意 ! 續費狀態為'處理中'已及域名為其他商戶分享來的，無法進行手動續費"
                      type="warning"
                      :closable='false'
                      style="margin-bottom:10px"
                      show-icon>
            </el-alert>
            <el-table :data="tableData"
                      @selection-change="handleSelectionChange"
                      @sort-change="handleSortChange"
                      ref="tableref"
                      v-loading="listLoading"
                      :row-key="getRowKey"
                      :row-class-name="tableRowClassName"
                      border
                      class="table">
                <el-table-column type="selection"
                                 :reserve-selection="true"
                                 :selectable="isDisabled">
                </el-table-column>
                <el-table-column prop="domain"
                                 label="域名"
                                 align="center" />
                <el-table-column prop="domain_expires_at"
                                 width="180"
                                 label="到期日期"
                                 align="center" />
                <el-table-column prop="status"
                                 label="域名狀態"
                                 align="center" />
                <el-table-column label="續約狀態"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.renew_status=='ACTIVE'">就緒</div>
                        <div v-if="scope.row.renew_status=='IN_PROCESSING'">處理中</div>
                    </template>
                </el-table-column>
                <el-table-column label="是否自動續費"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.is_renew_auto=='Y'">是</div>
                        <div v-else>否</div>
                    </template>
                </el-table-column>
                <el-table-column prop="source_name"
                                 label="網域來源"
                                 align="center" />
                <el-table-column width="120"
                                 label="來源註記"
                                 align="center">
                    <template #default="scope">
                        <el-tooltip class="item"
                                    effect="dark"
                                    :content="scope.row.remark"
                                    placement="top">
                            <div class="tool-tip-text"> {{scope.row.remark}}</div>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column prop="share_to"
                                 label="分享給"
                                 align="center" />
                <el-table-column label="分享來源"
                                 align="center">
                    <template #default="scope">
                        <div v-if="scope.row.share_from">
                            此為分享網域
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="activeName=='nonAutomatic'"
                                 label="手動續費"
                                 align="center">
                    <template #default="scope">

                        <el-button type="primary"
                                   v-if="scope.row.renew_status=='ACTIVE'&&!scope.row.share_from"
                                   :loading="renewBtnLoading"
                                   @click="renewBtn(scope.row)">續費</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <!-- 分頁 -->
            <div class="pagination">
                <el-pagination background
                               layout=" prev, pager, next"
                               :current-page="pageIndex"
                               :page-count="pageCount"
                               @current-change="handlePageChange">
                </el-pagination>
            </div>
            <RenewDialog :editVisible="editVisible"
                         :dialogTitle="dialogTitle"
                         :checkList="multipleSelection"
                         :renewType="renewType"
                         :textForm="textForm"
                         @refresh-data="handleSearch"
                         @close-dialog='editVisible = false'
                         @to-page='toPage'>
            </RenewDialog>

        </div>
    </div>
</template>

<script>
import { renewList, addCart } from '../../../api/index'
import RenewDialog from './components/RenewDialog.vue' //彈跳視窗
import { dateFormat } from '@/utils/dateFormat.js'
// import { Loading, Check, CircleClose } from '@element-plus/icons'
export default {
    components: {
        RenewDialog,
    },

    data() {
        return {
            formInline: {},
            tableData: [], //域名列表
            pageIndex: 1,
            pageCount: 0,
            multipleSelection: [], //打勾的域名列表
            activeName: 'nonAutomatic', //標籤頁 判斷自動非自動
            isRenewAuto: 'N',
            remarkList: {}, //網域來源
            editVisible: false, //燈窗顯示與否
            showProgress: false, //是否跑進度條
            dialogTitle: '請確認即將續費的域名', //談窗標題
            renewType: 'automatic', // 變更自動續費按鈕 自動:"automatic"  取消自動:"automaticCanncel"
            textForm: ['付款', '續費'],
            totalCount: 0, //共幾筆?
            //進度條設定
            percentage: 0,
            progressStatus: '',
            listLoading: false, //列表用
            order: '', //列表排序
            renewBtnLoading: false,
            shortcuts: [
                {
                    text: '1日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1)
                        return [start, end]
                    })(),
                },
                {
                    text: '7日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 7)
                        return [start, end]
                    })(),
                },
                {
                    text: '30日',
                    value: (() => {
                        const end = new Date()
                        const start = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return [start, end]
                    })(),
                },
            ],
            disabledDate(time) {
                const start = new Date()
                return start.getTime() - 3600 * 1000 * 24 * 1 > time.getTime() || time.getTime() > start.getTime() + 3600 * 1000 * 24 * 90
            },
        }
    },
    computed: {
        startDay() {
            if (this.formInline.datetime) {
                return dateFormat(this.formInline.datetime[0])
            }
            return ''
        },
        endDay() {
            if (this.formInline.datetime) {
                return dateFormat(this.formInline.datetime[1])
            }
            return ''
        },
    },
    mounted() {
        this.getData()
        let _this = this
        //怕他f5
        window.onbeforeunload = function () {
            if (_this.$route.name == 'renew' && _this.editVisible) {
                return '关闭提示'
            }
        }
    },
    methods: {
        getData() {
            this.listLoading = true
            if (this.activeName == 'nonAutomatic') {
                this.isRenewAuto = 'N'
            } else {
                this.isRenewAuto = 'Y'
            }
            let data = {
                domain: this.formInline.domain,
                source_id: JSON.stringify(this.formInline.remark),
                is_renew_auto: this.isRenewAuto,
                expired_at: this.startDay,
                expired_end: this.endDay,
                share_from: this.formInline.share_from,
                share_to: this.formInline.share_to,
                page: this.pageIndex,
                order: this.order,
            }
            renewList(data)
                .then((res) => {
                    if (res.code == 300) {
                        this.tableData = res.data.data_list
                        this.totalCount = res.data.total_count
                        this.pageCount = res.data.total_page
                        this.remarkList = res.data.source_select_list
                    } else if (res.code == 500) {
                        this.$message.error(res.message)
                    }
                    this.listLoading = false
                })
                .catch((err) => {
                    this.$message.error(err)
                    this.listLoading = false
                })
        },
        toPage(page, activeName) {
            this.$router.push({ path: page, query: { activeName: activeName } })
        },
        //查詢
        handleSearch() {
            this.clearSelect()
            this.getData()
        },
        //清除
        resetForm() {
            this.formInline = []
        },
        //勾選
        handleSelectionChange(val) {
            this.multipleSelection = []
            val.forEach((element) => {
                this.multipleSelection.push(element.domain_id)
            })
        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val
            this.getData()
        },
        //切換tabs
        changeTabs() {
            this.clearSelect()
            this.order = ''
            this.pageIndex = 1
            this.getData()
        },
        openManualDialog(type, title) {
            this.dialogTitle = title
            this.editVisible = true
            this.renewType = type
            if (this.renewType == 'automatic') {
                this.textForm = ['變更', '變更']
            } else {
                this.textForm = ['取消', '變更']
            }
        },
        getRowKey(row) {
            return row.domain_id
        },
        //可不可以勾選
        isDisabled(row) {
            if (row.renew_status == 'IN_PROCESSING') {
                return 0
            }
            return 1
        },
        //清除勾選
        clearSelect() {
            this.$refs.tableref.clearSelection()
        },
        //排序
        handleSortChange({ prop, order }) {
            if (order == 'descending') {
                this.order = prop + '_desc'
            } else if (order == 'ascending') {
                this.order = prop + '_asc'
            } else {
                this.order = ''
            }

            // 因為按下排序的時候他會重新計算表格寬, 然後又算不對導致旁邊會有個像scrollBar的東西
            // 解決方法一:按下排序後把資料清空(讓他計算空表格時的寬度, 排序資料都會重API撈)
            this.tableData = []
            this.getData()
        },
        //狀態
        tableRowClassName({ row }) {
            if (row.renew_status == 'IN_PROCESSING') {
                return 'warning-row'
            }
            return ''
        },
        //手動續費按鈕
        async renewBtn(row) {
            this.renewBtnLoading = true
            await this.doAddCart(row.domain_id)
            window.open(row.purchase_url, '_blank', 'toolbar=yes')
        },
        //加進購物車內API
        async doAddCart(id) {
            var ret = await new Promise((resolve) => {
                addCart({ domain_id: id.toString() })
                    .then(() => {
                        this.renewBtnLoading = false
                        resolve()
                    })
                    .catch(() => {
                        this.renewBtnLoading = false
                        this.$message.error('系統錯誤')
                    })
            })
            return ret
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    button {
        margin-left: 10px;
    }
}
.domain-form-inline {
    padding-top: 20px;
}
.add-btn {
    padding-bottom: 15px;
}
.table {
    width: 100%;
    font-size: 14px;
}
.progress {
    padding: 10px 0;
}
.span-color-primary {
    color: #409eff;
}
.span-color-danger {
    color: #ff0000;
}
.renew-el-dialog {
    text-align: center;
}
</style>
<style>
.el-table__header col[name='gutter'] {
    display: table-cell !important;
}
.el-table__body {
    width: 100% !important ;
}
.el-table .warning-row {
    background: #c0c4cc;
}
</style>